import { awaitCondition, maybe } from '../../../shared/util'
import { JitsuClientImpl } from '../jitsu/client'
import { ENV } from '../../../shared/types'
import { newErrorReporter } from '../log'
import { getSession } from './session_id'

export async function awaitSdkInitialized() {
  await awaitCondition(
    () => !!maybe(() => !!window.loomi_ctx.userId),
    500,
    101
  )
}

export function deepEqual(object1: any, object2: any) {
  if (!object1 && !object2) return true
  if (!object1 && !!object2) return false
  if (!!object1 && !object2) return false
  const keys1 = Object.keys(object1).sort((a, b) => a.localeCompare(b))
  const keys2 = Object.keys(object2).sort((a, b) => a.localeCompare(b))
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
  return true
}

function isObject(object: any) {
  return object != null && typeof object === 'object'
}

export function setGlobals(
  jitsu: JitsuClientImpl,
  env: ENV,
  storeAlias: string
) {
  const errorReporter = newErrorReporter(env, storeAlias)
  maybe(getSession)
  window.loomi = {
    ...(window.loomi || {}),
    jitsu,
    reportAnalyticsErr: maybe(() => errorReporter.withScript('loomi-analytics-sdk')),
    reportSdkErr: maybe(() => errorReporter.withScript('loomi-sdk')),
  }
}
