import { debug, error } from '../common/log'
import { maybe } from '../../../shared/util'
import { deferAfterPageLoad, shouldDeferResources } from '../../../shared/helpers'

let currentDevice: Device | undefined

export function reloadOnDeviceChange(): void {
  if (shouldDeferResources()) {
    deferAfterPageLoad(() => innerReloadOnDeviceChange()).catch(console.error)
  } else {
    innerReloadOnDeviceChange()
  }
}

function innerReloadOnDeviceChange() {
  const flagEnabled = maybe(() => window.visually.flags['reload-on-device-changed'] === true)
  if (!flagEnabled) return
  const query = window.matchMedia("(max-width: 960px)")
  query.removeEventListener('change', handler)
  query.addEventListener('change', handler)
}

export function getDeviceOverride(): string | undefined {
  return maybe(() => window.loomi_ctx.deviceOverride, undefined)
}

async function handler(ev: MediaQueryListEvent) {
  const newDevice = ev.matches ? Device.MOBILE : Device.DESKTOP
  if (currentDevice != newDevice) {
    debug(`device-listener`, `device changed to ${newDevice} from ${currentDevice}`)
    window.loomi_ctx.deviceOverride = newDevice === Device.MOBILE ? "m" : "d"
    currentDevice = newDevice
    const reload = maybe(() => window.visually.reload)
    if (reload) {
      await reload()
    } else {
      error(`device-listener`, `can't find reload function`)
    }
  }
}

export enum Device {
  MOBILE = "MOBILE",
  DESKTOP = "DESKTOP",
}
