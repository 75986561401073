import { awaitCondition, awaitForAddEventListeners, createDebouncedFn, maybe } from '../../shared/helpers'
import { Configuration, ENV, QueryHistoryEntry, UseCase } from '../../shared/types'
import { getTargetingContext, TargetingContext } from './targeting-context'
import { getTrackingId, overrideTrackingId } from './targeting-context/uniq_user_id'
import { updateHistoryArray } from './targeting-context/params_history'
import { paramsHistoryKey } from './common/storage_keys'
import { reportError } from './common/jitsu'
import { appendOptionsQueryParams } from './targeting-context/preview-params'
import { unsubscribeFromTriggers } from './triggers'
import { disconnectGlobalObserver } from './dom_mutator'
import { installCustomFonts } from './common/fonts'
import { applyUseCase, destroyTargetingListener, initTargetingChangedListener } from './triggers/usecase_applier'
import { addToCart } from './dom_mutator/loading_sdk/add_to_cart'
import { recommendProducts } from './dom_mutator/loading_sdk/recommend_products'
import { debug, error } from './common/log'
import { errToStr, getGoogleCDN, when } from '../../shared/util'
import { allocatorQuery, AllocatorQueryRequest, generateSecret } from './common/allocator_query'
import { experiencesHasEnhancedCartTargetingFormula } from './triggers/cart'
import { redirectTest } from './dom_mutator/utils'

export async function getShopifyCurrencyConverter() {
  return await fetch('https://cdn.shopify.com/s/javascripts/currencies.js')
    .then(res => res.text())
    .then(text => new Function(`${text};return Currency`)())
    .then((Currency: any) => {
      window.loomi_ctx = {
        ...(maybe(() => window.loomi_ctx) || {}),
        Currency
      }
    })
    .catch(e => console.error('fail currencies js', e))
}

export const BLOCKED_ERR = 'vslyb'

export function assertNotBlocked() {
  if (!!window.vsly_blocked) {
    throw new Error(BLOCKED_ERR)
  }
}

export function getHistoryParamsEq() {
  return (oldParam: QueryHistoryEntry) => (newParam: QueryHistoryEntry) =>
    newParam.v === oldParam.v && newParam.k === oldParam.k
}

function setCurrentProductInfo(config: Configuration) {
  const currentVariant = config.currentVariant
  window.loomi_ctx = {
    ...(window.loomi_ctx || {})
  }
  const productId = maybe(() => currentVariant!.pid)
  productId && (window.loomi_ctx.productId = `${productId}`)
  const variantId = maybe(() => currentVariant!.vid)
  variantId && (window.loomi_ctx.variantId = `${variantId}`)
  const price = maybe(() => currentVariant!.price)
  price && (window.loomi_ctx.variantPrice = price)
  const meta = maybe(() => currentVariant!.meta)
  meta && (window.loomi_ctx.productMeta = `${meta}`)
}

export async function getConfiguration(
  storeAlias: string,
  env: ENV,
  targetingContextOverride?: Partial<TargetingContext>
): Promise<{
    tracking: { id: string; firstSession: boolean };
    configuration: Configuration;
  }> {
  const trackingId = getTrackingId()
  const targetingContext = getTargetingContext(
    storeAlias,
    trackingId,
    targetingContextOverride
  )

  const config = await fetchConfig(env, targetingContext)
  maybe(() => {
    window.loomi_ctx.audiences = config.audiences
    // persisting audiences for post checkout
    maybe(() => config.audiences && localStorage.setItem('vsly_audiences', JSON.stringify(config.audiences)))
  })

  setCurrentProductInfo(config)
  updateHistoryArray<QueryHistoryEntry>(
    config.queryHistory!,
    targetingContext.queryHistory,
    getHistoryParamsEq(),
    paramsHistoryKey
  )

  disableCartEnrichmentIfNotTargetingEnhancedCart(config)

  return {
    configuration: config,
    tracking: trackingId,
  }
}

export function disableCartEnrichmentIfNotTargetingEnhancedCart(config: Configuration) {
  if (!experiencesHasEnhancedCartTargetingFormula(config.experiments)) {
    if (maybe(() => config.flags['sdk-enable-cart-enrichment'], false) === true) {
      config.flags['sdk-enable-cart-enrichment'] = false
    }
  }
}

export function initShopApi(
  configuration: Configuration,
  formatLocaleMoney: (amount: number) => any,
  hasLocaleMoneyFormatEnabled: () => any
) {
  window.loomi_api = {}
  window.loomi_api.when = when
  window.loomi_api.awaitCondition = awaitCondition
  window.loomi_api.debounce = createDebouncedFn
  window.loomi_api.allocatorQuery = (request: AllocatorQueryRequest) => {
    return allocatorQuery(request, generateSecret())
  }

  maybe(() =>
    configuration.shopApi!.forEach(({ js, windowKey }) => {
      try {
        window.loomi_api[windowKey] = new Function(js)
      } catch (e) {
        reportError()(`crushed: ${windowKey},${e}`)
      }
    })
  )

  if (hasLocaleMoneyFormatEnabled()) {
    window.loomi_api.formatLocaleMoney = formatLocaleMoney
  }
  window.loomi_api.redirectTest = redirectTest
}

function encode(targetingContext: TargetingContext) {
  return btoa(
    unescape(encodeURIComponent(JSON.stringify(targetingContext))),
  )
}

async function fetchConfig(
  env: ENV,
  targetingContext: TargetingContext
): Promise<Configuration> {
  const data = encode(targetingContext)
  const cfgUrl = appendOptionsQueryParams(getBackendHosts(env).cfgUrl)
  cfgUrl.searchParams.append('q', data)
  const fetchImpl = window.vslyNativeFetch || window.fetch
  const resp = await fetchImpl(cfgUrl.href)
  if (resp.ok) {
    return await resp.json()
  } else {
    throw new Error(`failed to load config: ${resp.statusText}`)
  }
}

export function persistClientSideAllocation(exp: UseCase) {
  const isABTest = typeof exp.chance === "number" && exp.chance < 100
  if (isABTest) { // we don't need to save allocation if exp is 100%
    const match = {
      experienceId: exp.name,
      variantId: exp.variant,
      storeAlias: maybe(() => window.loomi_ctx.storeAlias)!,
      anonymousId: maybe(() => window.loomi_ctx.userId)!
    } as any
    if (exp.version) {
      match.version = exp.version
    }
    const data = encode(match as any)
    const env = maybe(() => window.loomi_ctx.env, ENV.PROD)!
    const url = new URL(getBackendHosts(env).cfgUrl.replace('/allocate', '/match'))
    url.searchParams.append('q', data)
    fetch(url)
  }
}

export function getBackendHosts(env: ENV) {
  let path = '/api/allocator/web/public/allocate'
  if (isTestEnv(env)) {
    path = '/allocate'
  }
  return {
    cfgUrl: `${getBackendHost(env)}${path}`,
  }
}

export function getBackendHost(env: ENV): string {
  if (isTestEnv(env)) {
    return `http://localhost:8080`
  }
  return `https://${getGoogleCDN()}`
}

function isTestEnv(env: ENV) {
  return [ENV.TEST, ENV.LOCAL].includes(env)
}

export function persist(flags: any) {
  window.visually = {
    ...(maybe(() => window.visually) || {}),
    flags
  }
  localStorage.setItem('loomi-flags', JSON.stringify(flags))
}

export function consolidateUserId(uid: string, id: string) {
  window.loomi_ctx.userId = uid || id
  if (uid !== id) {
    overrideTrackingId(window.loomi_ctx.userId)
  }
}

export function sleep(ms = 40) {
  return new Promise(res => setTimeout(res, ms))
}

export async function destroy() {
  destroyTargetingListener()
  unsubscribeFromTriggers()
  disconnectGlobalObserver()
}

function persistCustomerTags() {
  const vslyCtags = 'vslyCtags'
  const ctags = window.loomi_ctx.ctags || []
  if (ctags.length === 0) {
    window.loomi_ctx.ctags = JSON.parse(localStorage.getItem(vslyCtags) || '[]')
  } else {
    localStorage.setItem(vslyCtags, JSON.stringify(ctags))
  }
}

export function setGlobals(
  storeAlias: string,
  env: ENV,
  config: Configuration,
  reload: (env: ENV, storeAlias: string, graceMS?: number) => Promise<void>,
  product?: { productId: string; variantId: string }
) {
  initTargetingChangedListener()
  window.loomi_ctx = {
    ...(maybe(() => window.loomi_ctx) || {}),
    env,
    storeAlias,
    apply: (experiment: UseCase) => {
      installCustomFonts([experiment])
      applyUseCase(experiment)
    },
  } as any
  maybe(persistCustomerTags)
  const pid = maybe(() => product!.productId)
  const ctx = window.loomi_ctx
  pid && !ctx.productId && (ctx.productId = pid)
  const vid = maybe(() => product!.variantId)
  vid && !ctx.variantId && (ctx.variantId = vid)

  window.loomi = {
    ...(maybe(() => window.loomi) || {}),
    conf: config,
  }
  window.visually = {
    ...(maybe(() => window.visually) || {}),
    flags: config.flags,
    sdk_api: {
      addToCart,
      recommendProducts,
    },
    reload: async () => {
      await reload(env, storeAlias, 1000)
    },
  } as any
}

export function handleBackForwardCache(
  env: ENV,
  storeAlias: string,
  reload: (env: ENV, storeAlias: string, graceMS?: number) => Promise<void>
) {
  const onpageshow = function(event: any) {
    if (maybe(() => !!event.persisted)) {
      reload(env, storeAlias, 500).catch(e => {
        error('failed  reloading sdk', e)
        const err = e as any
        reportError()(
          `init reloading sdk: ${errToStr(err)}, alias:${storeAlias}`
        )
      })
    }
  }
  if (maybe(() => !window.loomi_ctx.pageshow)) {
    debug(`pageshow initialized`)
    awaitForAddEventListeners().then(() => {
      window.addEventListener('pageshow', onpageshow)
      window.loomi_ctx.pageshow = true
    })
  }
}
