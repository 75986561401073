import {
  ProductMeta,
  VariantMeta,
} from '../core/dom_mutator/loading_sdk/recommend_products'
import { maybe } from '../../shared/util'
import { ShopifyAnalytics } from '../../shared/shopifyTypes'
import { debug } from '../core/common/log'

export function getCurrentProductDetails() {
  const shopifyAnalytics = maybe(
    () => window.ShopifyAnalytics
  ) as ShopifyAnalytics
  const shouldOverrideShopifyPdp = maybe(() => window.visually.flags['override-shopify-pdp-meta'])
  const product = maybe(() => shopifyAnalytics.meta.product) as ProductMeta
  const productId = maybe(() => (shouldOverrideShopifyPdp && maybe(() => window.loomi_ctx.productId)) || product.id)!
  const ret = {} as { productId: string; variantId: string }
  if (productId) {
    ret.productId = `${productId}`
  }
  const variantId = findCurrentVariantId(
    maybe(() => (shouldOverrideShopifyPdp && maybe(() => window.loomi_ctx.variantId)) || shopifyAnalytics.meta.selectedVariantId)!,
    product as ProductMeta
  )
  if (variantId) {
    ret.variantId = variantId
  }
  return ret
}

function isValidValue(variantId: any | string) {
  return (
    (typeof variantId === 'string' || typeof variantId === 'number') &&
    variantId !== '' &&
    variantId !== 0
  )
}

function takeVariantIdFromSearchParams() {
  try {
    return new URL(
      'http://' + window.location.pathname + window.location.search
    ).searchParams.get('variant') as string
  } catch (e) {
    debug('failed to get variant id from search params')
    return ''
  }
}

export function cleanVariantId(variantId: string | number) {
  const vid = Number(variantId)
  const ret = isNaN(vid)
    ? Number(`${variantId}`.replace(/[^\d.]+/g, '')) || undefined
    : vid
  if (typeof ret === 'undefined') {
    return undefined
  }
  return `${ret}`
}

export function findCurrentVariantId(
  variantId: string,
  product:
  | ProductMeta
  | undefined
  | {
    id: number | undefined;
    gid: string | undefined;
    vendor: string | undefined;
    type: string | undefined;
    variants: VariantMeta[] | undefined;
  }
) {
  if (!isValidValue(variantId)) {
    variantId = takeVariantIdFromSearchParams()
  }
  if (!isValidValue(variantId)) {
    variantId = maybe(() => (product as any).variants[0].id)
  }
  return cleanVariantId(variantId)
}
