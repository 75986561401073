import { MutationRequest, WidgetChange, WidgetContext } from './models'
import { MutateDeclarativeOptions } from './index'
import { maybe, sanitizeGaEvent } from '../../../shared/util'
import { Context } from '../../../shared/types'
import { isInEditor } from '../common/log'
import { getLogger } from '../../../analytics/core/log/log'

export function redirectTest(ctx: Context, url: string, redirectAfter = 10) {
  const {
    _USE_CASE,
    _USE_CASE_VARIANT,
    _USE_CASE_AUDIENCES,
    _USE_CASE_VERSION,
    _USE_CASE_GA_VARIANT,
    _USE_CASE_GA
  } = ctx

  if (isInEditor()) {
    getLogger().info(`Redirecting to ${url} with ${JSON.stringify(ctx)} will occur on live site, but not inside the editor`)
    return
  }

  const key = `${_USE_CASE}-${_USE_CASE_VARIANT}`
  const payload = {
    type: 'USE_CASE',
    payload: {
      sid: maybe(() => window.loomi_ctx.session!.id),
      user: {
        anonymous_id: maybe(() => window.loomi_ctx.userId),
      },
      use_case: _USE_CASE,
      use_case_variant: _USE_CASE_VARIANT,
      ...(_USE_CASE_VERSION ? { version: _USE_CASE_VERSION } : {}),
      ...(_USE_CASE_AUDIENCES ? { audiences: _USE_CASE_AUDIENCES } : {}),
    },
    options: {
      gaName: maybe(() => sanitizeGaEvent(_USE_CASE_GA!)),
      gaVariant: maybe(() => sanitizeGaEvent(_USE_CASE_GA_VARIANT!)),
    },
  }

  function hideBody() {
    if (document.body) {
      // @ts-ignore
      document.body.style.opacity = 0
    }
  }

  hideBody()

  setTimeout(() => {
    hideBody()
    const event = JSON.stringify(payload)
    const b64Event = btoa(unescape(encodeURIComponent(event)))
    sessionStorage.setItem(key, event)
    location.replace(`${url}${
      url.includes('?') ? '&' : '?'
    }vsly-redirected-from=${key}&vsly-redirected-event=${b64Event}`)
  }, redirectAfter)
}

export function injectOptionsToJavascript(js: string, options?: MutateDeclarativeOptions): string {
  if (js && options) {
    return `(function (){
    const _USE_CASE = "${options.experienceId}";
    const _USE_CASE_VARIANT = "${options.variantId}";
    const _USE_CASE_VERSION = ${maybe(() => options.version || 0, 0)};
    const _USE_CASE_AUDIENCES = "${maybe(() => window.loomi_ctx.audiences!.join('|'), '')}";
    const _USE_CASE_GA = "${maybe(() => options.gaExperienceName!.replace(/"/g, '\\"'), options.gaExperienceName)}";
    const _USE_CASE_GA_VARIANT = "${maybe(() => options.gaVariantName!.replace(/"/g, '\\"'), options.gaVariantName)}";
    const _USE_CASE_CTX = {_USE_CASE,_USE_CASE_VARIANT,_USE_CASE_VERSION,_USE_CASE_AUDIENCES,_USE_CASE_GA,_USE_CASE_GA_VARIANT};
    ${js}
  })()`
  }
  return js
}

export function buildContextFromOpts(opts: MutateDeclarativeOptions): Context {
  return {
    _USE_CASE: opts.experienceId!,
    _USE_CASE_VARIANT: opts.variantId!,
    _USE_CASE_VERSION: opts.version!,
    _USE_CASE_AUDIENCES: maybe(() => window.loomi_ctx.audiences!.join('|'), '')!,
    _USE_CASE_GA: opts.gaExperienceName!,
    _USE_CASE_GA_VARIANT: opts.gaVariantName!,
  } as Context
}

export function buildWidgetContext(
  req: MutationRequest,
  options?: MutateDeclarativeOptions,
): WidgetContext {
  const value = maybe(() => req.block!.value) as WidgetChange
  return {
    ...options,
    sectionId: maybe(() => value.env.sectionId),
    widgetId: maybe(() => value.widgetId),
    widgetVersion: maybe(() => value.version),
  } as WidgetContext
}

export function mergeRequestEnvWith(req: MutationRequest, anything: any) {
  const env = maybe(() => (req.block!.value as WidgetChange).env)
  if (!env) return
  Object.entries(anything).forEach(([k, v]) => (env[k] = v))
}

export function isDefined(str: string | undefined): boolean {
  if (str) return str !== ``
  return false
}

export function eachParent(
  element: HTMLElement,
  maxIterations: number,
  onParentVisited: (parent: HTMLElement) => void,
) {
  let temp = element
  let iter = 0
  while (temp.parentElement && iter < maxIterations) {
    onParentVisited(temp.parentElement)
    temp = temp.parentElement
    iter += 1
  }
}
