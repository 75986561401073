import { LoadingEnv, Product, ProductRecommendationsResponse, RecommendationOptions } from './recommendations_models'
import { parseCartItems } from '../../targeting-context'
import { getSessionProducts } from '../../targeting-context/products_viewed_in_session'
import { hash, WindowCache } from '../../common/cache'
import { WidgetContext } from '../models'
import { getPurchasedProducts } from '../../targeting-context/purchased_products'
import { getCart, getCartItemHistory } from '../../targeting-context/cart'
import { getGoogleCDN, maybe } from '../../../../shared/util'
import { getCurrentProductDetails } from '../../../shopify/current-product'
import { error } from '../../common/log'
import { isFbEnabled } from '../../../../shared/fbits'

const recsCacheName = 'recs_cache'
const recsCacheTTL = 5 * 60 * 1000 + 1
const recsCache = new WindowCache<ProductRecommendationsResponse>(
  recsCacheName,
  recsCacheTTL
)

function encode(options: RecommendationOptions) {
  return btoa(
    unescape(
      encodeURIComponent(
        JSON.stringify({
          ...options,
        }),
      ),
    ),
  )
}

function getLocale() {
  return maybe(() => window.Shopify.locale) || maybe(() => window.visually.locale)
}

export const recommendProducts = async (
  loadingEnv?: LoadingEnv,
  widgetCtx?: WidgetContext
): Promise<Array<Product>> => {
  const empty =
    Promise.resolve([])
  try {
    if (!loadingEnv) {
      return empty
    }
    const options = maybe(() => loadingEnv.recommendationOptions)
    if (!options) {
      return empty
    }
    setProductId(options)
    options.cartItems = takeIds(parseCartItems())
    options.currency = maybe(() => getCart()!.currency, 'USD')
    options.cart_total = maybe(() => getCart()!.total_price, 0)

    const CART_ITEMS = "CART_ITEMS"

    if (!![
      'RECENTLY_VIEWED',
      'VIEWED_WITH_RECENTLY_VIEWED']
      .find(x => hasRecType(options, x))) {
      options.recentlyViewed = takeIds(getSessionProducts())!
    }
    const purchasedProducts = getPurchasedProducts()
    if (purchasedProducts.length > 0) {
      options.pastPurchases = purchasedProducts
    }
    if (hasRecType(options, CART_ITEMS)) {
      options.cartHistory = getCartItemHistory()
    }

    options.userId = maybe(() => window.loomi_ctx.userId, '')!

    if (widgetCtx) {
      options.widgetContext = widgetCtx
    }
    if (maybe(() => !!window.loomi_ctx.storeAlias)) {
      options.storeAlias = window.loomi_ctx.storeAlias!
    }
    options.locale = getLocale()

    const query = encode(options)

    const queryHash = hash(query)
    let response = recsCache.get(queryHash)
    if (!response) {
      const fetchImpl = window.vslyNativeFetch || window.fetch
      const domain = getGoogleCDN(options.env)
      const resp = await fetchImpl(
        `https://${domain}/api/recommendations/web/public/recommend?q=${query}`
      )
      response = (await resp.json()) as ProductRecommendationsResponse
      maybe(() => [optimizeImageWidth, window.loomi_api.onRecsResp].forEach(f => f && f(response)))
      recsCache.set(queryHash, response)
    }

    return maybe(() => response!.products)!
  } catch (e) {
    error('loomi-widget,recProducts failed:', e)
    return empty
  }
}

export function takeIds(
  x: {
    productId: any;
    variantId: any;
    quantity?: number;
  }[]
) {
  return maybe(() =>
    x
      .filter(x => !!x.productId)
      .map(x => {
        return ({
          productId: `${x.productId}`,
          variantId: `${x.variantId}`,
          quantity: x.quantity || 1,
        })
      })
  )
}

export interface VariantMeta {
  id: any;
  price: number;
  name: string;
  public_title: string;
  sku: string;
}

export interface ProductMeta {
  id: number;
  gid: string;
  vendor: string;
  type: string;
  variants: VariantMeta[];
}

export function setProductId(options: RecommendationOptions) {
  const { productId, variantId } = getCurrentProductDetails()
  if (!!productId || !!variantId) {
    options.productId = { productId, variantId }
  } else if (window.loomi_ctx.productId) {
    options.productId = { productId: window.loomi_ctx.productId }
    if (window.loomi_ctx.variantId) {
      options.productId.variantId = window.loomi_ctx.variantId
    }
  }
}

function hasRecType(options: RecommendationOptions, recType: string) {
  return options.type === recType ||
    maybe(() => options!.strategyPerSlot!.find(s => s.strategy === recType)) ||
    maybe(() => !!Object.values(options.conditions).find((v: any) => v.qbProps.envKey === recType)) ||
    maybe(() =>
      !!options.layeredRuling!.find(s => s.strategy === recType ||
      !!maybe(() => s.strategyPerSlot!.slots.find(s => s.strategy === recType))))
}

export function optimizeImageWidth(response: ProductRecommendationsResponse | undefined) {
  if (isFbEnabled('opt-img')) {
    const width = getInitialImageWidth()
    response!.products.forEach((product) => {
      const url = new URL(product.image.src)
      url.searchParams.set('width', `${Math.min(width, product.image.width || 1280)}`)
      product.image.src = url.href
    })
  }
}

function getInitialImageWidth() {
  const w = window.innerWidth
  return w <= 480 ? 480 : w <= 768 ? 640 : w <= 1024 ? 960 : 1280
}
