import { CartBase } from '../../../shared/shopifyTypes'
import { UseCase } from '../../../shared/types'
import { MutationController } from '../dom_mutator/models'
import { getJitsuClient, JitsuClient, reportError } from '../common/jitsu'
import { mutateDeclarative } from '../dom_mutator'
import { getCart } from '../targeting-context/cart'
import { debug, error } from '../common/log'
import {
  awaitForAddEventListeners,
  awaitForRemoveEventListeners,
  maybe,
} from '../../../shared/helpers'
import {
  TARGETING_EVENT_NAME,
} from '../../../shared/consts'
import { TargetingEventType } from '../../../shared/events'
import { CartHasItem, CartHasSubs, CartItemHasCollection, CartItemHasTag, CartSubs, CartTotal } from './cart'
import { IsLoyaltyCustomer, LoyaltyPointsBalance, LoyaltyPointsEarned } from './loyalty'
import { persistClientSideAllocation } from '../init'
import { report } from '../common'
import { PageVisit } from './page_visits'

export interface ClientTargetingContext {
  PageVisit: (
    value: string,
    timeframe: string,
    _: 'contains' | 'matches',
    includeQuery: boolean,
  ) => Promise<boolean>;
  Total: (currency: string, autoApply?: boolean) => number;
  CartHasSubs: (value: string, op: string) => boolean;
  CartSubs: () => Array<string>;
  CustomerTag: (tag: string) => boolean
  CartHasItem: (value: string) => boolean;
  CartItemHasTag: (value: string) => boolean;
  CartItemHasCollection: (value: string) => boolean;
  cart: CartBase | undefined;

  IsLoyaltyCustomer: () => boolean;
  LoyaltyPointsBalance: () => number;
  LoyaltyPointsEarned: () => number;
}

let isInitialized = false
const experiencesToWatch: Record<string, UseCase> = {}
const appliedExperience: Record<string, MutationController[]> = {}

export function cleanExperiencesToWatch() {
  maybe(() => Object.keys(experiencesToWatch).forEach(key => {
    delete experiencesToWatch[key]
  }))
}

const formatExp = (exp: UseCase) => `${exp.name}-${exp.variant}`

function getClientTargetingContext(): ClientTargetingContext {
  return {
    cart: getCart(),
    Total: CartTotal,
    CartHasSubs,
    CartSubs,
    CartHasItem,
    CartItemHasTag,
    CartItemHasCollection,
    IsLoyaltyCustomer,
    LoyaltyPointsBalance,
    LoyaltyPointsEarned,
    PageVisit,
    CustomerTag
  }
}

function setMutationControllers(
  experience: UseCase,
  controllers: MutationController[],
) {
  appliedExperience[formatExp(experience)] = controllers
}

function applyChanges(experiment: UseCase) {
  const experimentControllers = maybe(() => experiment.code
    .map(change => {
      try {
        return mutateDeclarative(change, {
          experienceId: experiment.name,
          variantId: experiment.variant,
          gaExperienceName: experiment.gaName,
          gaVariantName: experiment.gaVariant,
          publishedAt: experiment.publishedAt,
          version: experiment.version
        })
      } catch (ex) {
        reportError()(
          `running experience failed with err: ${(ex as any).toString()} \n block: ${JSON.stringify(
            change,
          )}`,
        )
      }
      return undefined
    })
    .filter(controller => !!controller), []) as MutationController[]
  setMutationControllers(experiment, experimentControllers)
}

async function revertChangesWithUnmetConditions(
  afterEval: {
    exp: UseCase;
    shouldBeApplied: Promise<boolean>;
    hasBeenApplied: boolean;
  }[],
) {
  const after = await Promise.all(afterEval.map(async v => ({ ...v, shouldBeApplied: await v.shouldBeApplied })))
  after
    .filter(item => item.hasBeenApplied && !item.shouldBeApplied)
    .flatMap(item => {
      const controller = maybe(() => appliedExperience[formatExp(item.exp)])
      if (controller) delete appliedExperience[formatExp(item.exp)]
      return controller
    })
    .forEach(controller => {
      maybe(controller!.revert)
    })
}

const reportedExperiences = new Set<string>()

async function applyChangesThatMetConditions(
  afterEval: {
    exp: UseCase;
    shouldBeApplied: Promise<boolean>;
    hasBeenApplied: boolean;
  }[],
) {
  const after = await Promise.all(afterEval.map(async v => ({ ...v, shouldBeApplied: await v.shouldBeApplied })))
  after
    .filter(item => item.shouldBeApplied && !item.hasBeenApplied)
    .forEach(item => {
      applyChanges(item.exp)
      reportClientSideTargeting(item.exp, reportedExperiences)
    })
}

export function hasClientSideFormula(experience: UseCase) {
  const clientTargetingFormula = maybe(() => experience.clientTargetingFormula)
  return clientTargetingFormula && clientTargetingFormula !== ``
}

function transpileFormula(formula: string | undefined) {
  if (maybe(() => formula!.includes(`c.PageVisit`) && !formula!.includes(`await c.PageVisit`))) {
    return `(async () => (${formula!.replaceAll('c.PageVisit', 'await c.PageVisit')}))()`
  }
  return formula
}

async function shouldApplyChangesBasedOnTargeting(
  experience: UseCase,
  ctx: ClientTargetingContext,
): Promise<boolean> {
  if (hasClientSideFormula(experience)) {
    ctx.Total = CartTotal
    ctx.CartHasSubs = CartHasSubs
    ctx.CartSubs = CartSubs
    ctx.CartHasItem = CartHasItem
    ctx.CartItemHasTag = CartItemHasTag
    ctx.CartItemHasCollection = CartItemHasCollection
    ctx.IsLoyaltyCustomer = IsLoyaltyCustomer
    ctx.LoyaltyPointsBalance = LoyaltyPointsBalance
    ctx.LoyaltyPointsEarned = LoyaltyPointsEarned
    ctx.PageVisit = PageVisit
    ctx.CustomerTag = CustomerTag
    const condition = () =>
      new Function(`
        const c = arguments[0];
        return ${(transpileFormula(experience.clientTargetingFormula))}`)(ctx)
    return new Promise<boolean>(r => r(condition() as boolean | Promise<boolean>)).catch(e => {
      error('client side formula err', e)
      return false
    })
  }
  return Promise.resolve(true)
}

async function onTargetingContextChanged(ctx: ClientTargetingContext) {
  const afterEval = Object.entries(experiencesToWatch).map(([_, exp]) => {
    if (hasClientSideFormula(exp)) {
      experiencesToWatch[formatExp(exp)] = exp
    }

    return {
      exp,
      shouldBeApplied: shouldApplyChangesBasedOnTargeting(exp, ctx),
      hasBeenApplied: !!appliedExperience[formatExp(exp)],
    }
  })
  await revertChangesWithUnmetConditions(afterEval)
  await applyChangesThatMetConditions(afterEval)
}

const handler = (event: Event) => {
  // @ts-ignore
  const eventType = maybe(() => event.key)
  // @ts-ignore
  const eventPayload = maybe(() => event.value)
  const ctx = getClientTargetingContext()
  if (eventType === TargetingEventType.CART_CHANGE) {
    onTargetingContextChanged({
      ...ctx,
      cart: eventPayload,
    })
    maybe(() => window.loomi_ctx.maintainCartAttributes!(eventPayload as CartBase))
  } else if (eventType === TargetingEventType.LOYALTY_CHANGE) {
    onTargetingContextChanged(ctx)
  }
}

export async function applyUseCase(experiment: UseCase): Promise<boolean> {
  initTargetingChangedListener()

  if (hasClientSideFormula(experiment)) {
    experiencesToWatch[formatExp(experiment)] = experiment
  }

  const shouldApply = await shouldApplyNow(experiment)
  if (!shouldApply) {
    debug(
      `exp: ${experiment.gaName} not run client targeting: ${experiment.clientTargetingFormula} => false`,
    )
    return false
  }
  applyChanges(experiment)
  return true
}

export function shouldApplyNow(experiment: UseCase) {
  return shouldApplyChangesBasedOnTargeting(experiment, getClientTargetingContext())
}

export function initTargetingChangedListener() {
  if (!isInitialized) {
    awaitForAddEventListeners(() => document).then(() => {
      document.addEventListener(TARGETING_EVENT_NAME, handler)
      isInitialized = true
    })
  }
}

export function destroyTargetingListener() {
  if (isInitialized) {
    awaitForRemoveEventListeners(() => document).then(() => {
      document.removeEventListener(TARGETING_EVENT_NAME, handler)
      isInitialized = false
    })
  }
}

export function reportClientSideTargeting(exp: UseCase, reportedExperiencesSet: Set<string>, jitsu: JitsuClient = getJitsuClient()) {
  if (reportedExperiencesSet.has(exp.variant)) {
    return
  }
  report(jitsu, exp)
  reportedExperiencesSet.add(exp.variant)
  persistClientSideAllocation(exp)
}

function CustomerTag(
  tag: string,
  op?: string
): boolean {
  if (maybe(() => op!.includes("!"))) {
    return !maybe(() => window.loomi_ctx.ctags!.includes(tag))
  }
  return window.loomi_ctx.ctags!.includes(tag)
}
