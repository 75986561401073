import { initialize, reload } from './init-sdk'
import { ENV } from '../../shared/types'
import { parseScriptParams } from '../../shared/bootstrap'
import { awaitCondition, maybe, URL_CHANGED_EVENT } from '../../shared/util'
import { awaitForAddEventListeners, createDebouncedFn } from '../../shared/helpers'
import { CurrentProduct, PushStateArguments, VisuallyInstrument } from './types'
import { CartBase } from '../../shared/shopifyTypes'
import { onCartChange } from '../../analytics/core/track/cart'
import { AnalyticEvent } from '../core/common/events'
import { monitorInfiniteLoop } from '../../analytics/core/util/helpers'

export function bootstrap() {
  window.vslyIntegrationType = `spa`
  const { env, storeAlias } = parseScriptParams()
  window.loomi_ctx = { ...(window.loomi_ctx || { }), env, storeAlias: storeAlias! }
  window.visually = {
    ...(window.visually || {}),
    visuallyConnect: visuallyConnect(storeAlias as string, env as ENV),
    onCartChanged,
    onUserIdChange,
    localeChanged,
    productChanged,
    customerTagsChanged,
  }
  initialize(env as ENV, storeAlias as string)
}

let init = false
export const visuallyConnect = (alias: string, env: ENV) => (instrument: VisuallyInstrument) => {
  try {
    window.visually.addCartItem = instrument.addToCart
    window.visually.cartClear = instrument.cartClear
    window.visually.openDrawer = instrument.openCartDrawer
    window.visually.updateCartAttributes = instrument.cartAddAttributes
    window.loomi_ctx.productId = maybe(() => instrument.initialProductId.toString())
    window.loomi_ctx.variantId = maybe(() => instrument.initialVariantId.toString() || maybe(() => new URL(location.href).searchParams.get("variant"))!)
    window.loomi_ctx!.current_product = instrument.currentProduct
    window.loomi_ctx.variantPrice = instrument.initialVariantPrice
    window.loomi_ctx.clientId = instrument.initialUserId
    window.loomi_ctx.ctags = instrument.customerTags || []
    window.visually.locale = instrument.initialLocale || 'en'
    window.visually.pageType = instrument.pageType
    window.visually.currency = instrument.initialCurrency || 'USD'
    if (!init) {
      dispatchUrlChangedOnClick()
      subscribeToUrlChanges(env, alias)
      init = true
    }
  } catch (e) {
    console.error(`vsly sdk init fail`, e)
  }
}

function subscribeToUrlChanges(env: ENV, storeAlias: string) {
  awaitForAddEventListeners().then(() => {
    window.addEventListener(URL_CHANGED_EVENT, (_: any) =>
      reload(env, storeAlias, 1)
    )
  })
}

const safeTrackATC = monitorInfiniteLoop(() => () => window.loomi.jitsu!.track(AnalyticEvent.ADD_TO_CART, {}), "spa_atc")
const debouncedSafeTrackATC = createDebouncedFn(safeTrackATC, 500)

export function onCartChanged(cart: CartBase) {
  if (!cart) return
  const currentItemsCount = maybe(() => window.loomi_ctx.cart!.item_count || 0, 0)!
  if (maybe(() => cart.item_count)! > currentItemsCount) {
    debouncedSafeTrackATC()
  }
  onCartChange(cart)
}

function noHostName(href: string) {
  return href.replace(`https://${window.location.hostname}`, '')
}

let prevHref = noHostName(window.location.href)
function handleUrlChanged(next: string) {
  try {
    const nextUrl = noHostName(next)
    requestAnimationFrame(() => {
      if (prevHref !== nextUrl && !!nextUrl) {
        prevHref = nextUrl
        window.dispatchEvent(
          new CustomEvent(URL_CHANGED_EVENT, {
            detail: {
              prev: prevHref,
              curr: next,
            },
          }),
        )
      }
    })
  } catch (e) {}
}

export function dispatchUrlChangedOnClick() {
  awaitCondition(() => !!maybe(() => history.pushState)).then(() => {
    const pushState = history.pushState
    history.pushState = function () {
      // @ts-ignore
      pushState.apply(history, arguments)
      // @ts-ignore
      const next: string = maybe(() => (arguments as PushStateArguments)['2'])
      handleUrlChanged(next)
    }
    awaitCondition(() => !!maybe(() => history.replaceState)).then(() => {
      const replaceState = history.replaceState
      history.replaceState = function () {
        // @ts-ignore
        replaceState.apply(history, arguments)
        // @ts-ignore
        const next: string = maybe(() => (arguments as PushStateArguments)['2'])
        handleUrlChanged(next)
      }
    })
  })
}

const localeChanged = (locale: string) => {
  if (window.visually.locale !== locale) {
    window.visually.locale = locale
  }
}

const customerTagsChanged = (newTags: [string]) => {
  if (newTags && newTags.length > 0) {
    window.loomi_ctx.ctags = newTags
  }
}

const productChanged = (product: CurrentProduct) => {
  if (product && maybe(() => window.loomi_ctx.current_product!.id) !== product.id) {
    window.loomi_ctx.current_product = product
  }
}

export function onUserIdChange(clientId: string) {
  window.loomi_ctx.clientId = clientId
  if (clientId) {
    window.localStorage.setItem(`vsly__st`, clientId)
  }
}
